/** ready */
window.addEventListener('DOMContentLoaded', () => {
    scrollfixed();
    headerscroll();
});

/** load */
window.addEventListener('load', () => {
    scrollfixed();
    headerscroll();
});

/** resize */
window.addEventListener('resize', () => {
    scrollfixed();
    headerscroll();
});

/** scroll */
window.addEventListener('scroll', () => {
    scrollfixed();
    headerscroll();
});

const scrollfixed = () => {
    let ls = window.scrollX;
    let target = [
        '#header',
        '.aside-close',
        '.aside-bg',
        '.main-section .visual .movie',
        '.main-section .visual .text',
        '.main-section .slogan',
        '.family-floating',
        '.history-section .rightarea',
    ];

    document.querySelectorAll(target).forEach((a) => {
        a.style.left = (ls * -1) + 'px';
    });
};

const scrollextend = {
    init(a) {
        this.interaction(a);
        window.addEventListener('load', () => {
            this.interaction(a);
        });
        window.addEventListener('resize', () => {
            this.interaction(a);
        });
        window.addEventListener('scroll', () => {
            this.interaction(a);
        });
    },
    interaction(a) {
        let stage = document.querySelector(a);
        let hint = stage.querySelector('.scroll-hint');
        let trigger = stage.querySelector('.scroll-trigger');
        let st = stage.getBoundingClientRect().top - window.innerHeight;
        let tt = trigger.getBoundingClientRect().top - window.innerHeight;

        if(tt <= 0) {
            stage.classList.add('extend');
        }

        if(st > 0) {
            stage.classList.remove('extend');
        }
    }
};

const scrollrise = {
    init(a) {
        this.interaction(a);
        window.addEventListener('load', () => {
            this.interaction(a);
        });
        window.addEventListener('resize', () => {
            this.interaction(a);
        });
        window.addEventListener('scroll', () => {
            this.interaction(a);
        });
    },
    interaction(a) {
        let target = document.querySelectorAll(a);
        
        target.forEach((stage) => {
            let st = stage.getBoundingClientRect().top - window.innerHeight;
            if(st + stage.clientHeight <= 0) {
                for(let i of stage.querySelectorAll('.rise')) {
                    i.classList.add('on' + itemIndex(i));
                }
            }
    
            if(st > 0) {
                for(let i of stage.querySelectorAll('.rise')) {
                    i.classList.remove('on' + itemIndex(i));
                }
            }
        });
    }
};

const mainscrollslice = {
    init() {
        this.interaction();
        window.addEventListener('load', () => {
            this.interaction();
        });
        window.addEventListener('resize', () => {
            this.interaction();
        });
        window.addEventListener('scroll', () => {
            this.interaction();
        });
    },
    interaction() {
        let target = document.querySelector('.main-section .slogan');
        let stage = target.parentElement;
        let fake = document.querySelector('.main-section .infinite');
        let gap = 80;
        let tit = stage.querySelector('.main-title') ? stage.querySelector('.main-title').clientHeight : 0;
        let st = stage.getBoundingClientRect().top - gap + tit;
        let et = stage.getBoundingClientRect().top - gap - window.innerHeight + stage.clientHeight + (window.innerHeight - target.clientHeight);

        if(st <= 0 && et > 0) {
            target.classList.remove('default');
            target.style.position = 'fixed';
            target.style.top = gap + 'px';
            target.style.bottom = 'auto';
            fake.style.cssText = 'position:fixed;top:' + (target.getBoundingClientRect().top + target.clientHeight + 200) +'px;';
        }
        else if(st < 0 && et <= 0) {
            target.classList.add('default');
            target.style.position = 'absolute';
            target.style.top = 'auto';
            target.style.bottom = 0;
            fake.style.cssText = '';
        }
        else {
            target.classList.add('default');
            target.style.position = 'absolute';
            target.style.top = 'auto';
            target.style.bottom = 'auto';
            fake.style.cssText = 'position:fixed;top:' + (target.getBoundingClientRect().top + target.clientHeight + 200) +'px;';
        }
    }
};

const mainnewsswiper = {
    init() {
        let target = document.querySelector('.main-section .article');
        let bar = target.parentElement.querySelector('.swiper-scrollbar');
        
        new Swiper(target, {
            direction: 'horizontal',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
            mousewheel: true,
        });

        target.addEventListener('mouseenter', () => {
            if(! bar.classList.contains('swiper-scrollbar-lock')) {
                pcscroll.ban();
            }
        });
        target.addEventListener('mouseleave', () => {
            pcscroll.clear();
        });
    }
};

const mainnoticeswiper = {
    init() {
        let target = document.querySelector('.main-section .notice');

        new Swiper(target, {
            direction: 'horizontal',
            slidesPerView: 'auto',
            freeMode: true,
            mousewheel: false,
        });
    }
};

const mainlayerpopup = {
    init() {
        modal.in('main-popup');

        let target = document.querySelector('.mainpopup .swiper');
        
        new Swiper(target, {
            autoHeight: true,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
        });
    }
};

const familyfloating = {
    init() {
        this.interaction();
        window.addEventListener('load', () => {
            this.interaction();
        });
        window.addEventListener('resize', () => {
            this.interaction();
        });
        window.addEventListener('scroll', () => {
            this.interaction();
        });
    },
    interaction() {
        let target = document.querySelectorAll('.family-floating');
        let tt = document.querySelector('.main-section .visual').getBoundingClientRect().top + 130;

        target.forEach((a) => {
            if(tt <= 0) {
                a.style.opacity = 1;
                a.classList.add('on');
            }
            else {
                a.style.opacity = 0;
                a.classList.remove('active', 'on');
            }
        });
    }
};

const instroducebusinesslink = () => {
    let item = document.querySelectorAll('.businesslink-section .item');
    
    item.forEach((li) => {
        li.addEventListener('mouseenter', () => {
            for(let i of item) {
                i.classList.remove('active');
            }
            li.classList.add('active');
        });
    });
    
    
};

const introducehistory = {
    init() {
        this.interaction();
        window.addEventListener('load', () => {
            this.interaction();
        });
        window.addEventListener('resize', () => {
            this.interaction();
        });
        window.addEventListener('scroll', () => {
            this.interaction();
        });
    },
    interaction() {
        let wrap = document.querySelectorAll('.history-section .list');
        let gap = 80;

        wrap.forEach(stage => {
            let target = stage.querySelector('.rightarea');
            let img = target.querySelector('.swiper');
            let tt = stage.getBoundingClientRect().top - window.innerHeight;
            let st = stage.getBoundingClientRect().top - gap;
            let et = stage.getBoundingClientRect().top - gap - window.innerHeight + stage.clientHeight + (window.innerHeight - target.clientHeight);

            if(tt + 160 <= 0) {
                for(let i of stage.children) {
                    i.classList.add('on');
                }
                if(! img.classList.contains('swiper-initialized')) {
                    new Swiper(img, {
                        speed: 500,
                        loop: true,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                    });
                }
            }
            if(tt > 0) {
                for(let i of stage.children) {
                    i.classList.remove('on');
                }
            }

            if(st <= 0 && et > 0) {
                target.classList.remove('default');
                target.style.position = 'fixed';
                target.style.top = gap + 'px';
                target.style.bottom = 'auto';
            }
            else if(st < 0 && et <= 0) {
                target.classList.add('default');
                target.style.position = 'absolute';
                target.style.top = 'auto';
                target.style.bottom = 0;
            }
            else {
                target.classList.add('default');
                target.style.position = 'absolute';
                target.style.top = 0;
                target.style.bottom = 'auto';
            }
        });
    }
};

const businessvisual = {
    init() {
        let target = document.querySelector('.businesssummary-section .visual');
        if(target.querySelectorAll('.swiper-slide').length > 1) {
            target.querySelector('.prev').classList.remove('blind');
            target.querySelector('.next').classList.remove('blind');
            target.querySelector('.page').classList.remove('blind');
            target.querySelector('.remote').classList.remove('blind');

            let swiper = new Swiper(target, {
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    prevEl: '.prev',
                    nextEl: '.next',
                },
                pagination: {
                    el: '.page',
                    type: 'custom',
                    renderCustom: function(swiper, current, total) {
                        current < 10 ? current = '0' + current : current = current;
                        total < 10 ? total = '0' + total : total = total;
                        return current + ' ∙ ' + '<span>' + total + '</span>';
                    },
                },
            });
            let remote = target.querySelector('.remote');
            remote.addEventListener('click', () => {
                if(remote.classList.contains('active')) {
                    swiper.autoplay.start();
                }
                else {
                    swiper.autoplay.stop();
                }
                remote.classList.toggle('active');
            });
        }
    },
};