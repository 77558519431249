window.addEventListener('DOMContentLoaded', () => {
    selectbox.init();
});

const selectbox = {
    init(a) {
        let select;
        if(a) {
            select = document.getElementsByName(a);
        }
        else {
            select = document.querySelectorAll('select');
        }
        
        select.forEach((a) => {
            if(! a.parentElement.classList.contains('selectbox')) {
                let wrap = document.createElement('div');
                wrap.classList.add('selectbox');
                wrap.innerHTML = a.outerHTML;
                a.parentElement.insertBefore(wrap, a);
                a.remove();
                let text = document.createElement('span');
                text.classList.add('text');
                text.innerHTML = a.options[a.selectedIndex].text;
                wrap.append(text);
                text.addEventListener('click', () => {
                    if(wrap.classList.contains('active')) {
                        this.close(wrap);
                    }
                    else {
                        this.open(wrap, wrap.querySelector('select'));
                    }
                });
            }
            else {
                a.parentElement.querySelector('.text').innerHTML = a.options[a.selectedIndex].text;
            }
        });
    },
    open(a, b) {
        for(let i of document.querySelectorAll('.selectbox')) {
            i.classList.remove('active');
            if(i.querySelector('.list')) {
                i.querySelector('.list').remove();
            }
        }
        
        let h = 1;
        let ul = document.createElement('ul');
        ul.classList.add('list');
        for(let i of b.children) {
            if(i.selected == false) {
                h = h + 50;
                let li = document.createElement('li');
                li.innerHTML = i.text;
                ul.append(li);
                li.addEventListener('click', () => {
                    this.setting(a, b, li);
                });
            }
        }

        a.classList.add('active');
        a.append(ul);
        setTimeout(() => {
            h > 251 ? h = 251 : h = h;
            ul.style.height = h + 'px';
            ul.addEventListener('transitionend', () => {
                ul.style.overflowY = 'auto';
            }, {once: true});
        },0);

        window.addEventListener('click', (event) => {
            if(event.target != a && event.target.closest('.selectbox') == null) {
                this.close(a);
            }
        });
    },
    close(a) {
        a.classList.remove('active');
        if(a.querySelector('.list')) {
            a.querySelector('.list').remove();
        }
    },
    setting(a, b, c) {
        for(let i of b.children) {
            if(i.text == c.innerHTML) {
                i.selected = true;
            }
            else {
                i.selected = false;
            }
        }
        a.querySelector('.text').innerHTML = b.options[b.selectedIndex].text;
        b.dispatchEvent(new Event('change'));
        this.close(a);
    }
};

const emailPathReady = () => {
    let stage = document.querySelectorAll('.email-cell');

    stage.forEach((td) => {
        let target = td.querySelector('select');
        let item;
        if(target.parentElement.classList.contains('selectbox')) {
            item = target.parentElement.previousElementSibling;
        }
        else {
            item = target.previousElementSibling;
        }
    
        if(itemIndex(target.options[target.selectedIndex]) == 0) {
            if(item.readOnly == true) {
                item.value = '';
            }
            item.readOnly = false;
        }
        else {
            item.value = target.options[target.selectedIndex].text;
            item.readOnly = true;
        }
    });
};

const emailPath = a => {
    let target = document.querySelector('select[name='+ a +']');
    let item;
    if(target.parentElement.classList.contains('selectbox')) {
        item = target.parentElement.previousElementSibling;
    }
    else {
        item = target.previousElementSibling;
    }

    if(itemIndex(target.options[target.selectedIndex]) == 0) {
        if(item.readOnly == true) {
            item.value = '';
        }
        item.readOnly = false;
    }
    else {
        item.value = target.options[target.selectedIndex].text;
        item.readOnly = true;
    }
};

const fileUploadAdd = a => {
    let fake = a.parentElement.querySelector('input[type=text]');

    if(a.value) {
        fake.value = a.value;
    }

    /**
     * (같은 파일 업로드시), (게시물수정하기 - 등록된 첨부파일) 동일한 onchange trigger 동작을 위해 input file의 value를 비워줌
     */
    a.value = '';
};

const calendar = {
    o : '',
    year : '',
    month : '',
    in(a, b) {
        this.o = a;
        if(a.classList.contains('inactive')) {
            a.classList.remove('inactive');
            document.querySelector('.calendar-stage').remove();
            return false;
        }
        
        a.classList.add('inactive');
        
        let stage = document.createElement('div');
        let navi = document.createElement('div');
        let head = document.createElement('div');
        let body = document.createElement('div');
        let etc = document.createElement('div');
		let prev = document.createElement('button');
		let next = document.createElement('button');
		let sely = document.createElement('select');
		let selm = document.createElement('select');
		let today = document.createElement('button');
		let close = document.createElement('button');

        prev.setAttribute('type', 'button');
        next.setAttribute('type', 'button');
        today.setAttribute('type', 'button');
        close.setAttribute('type', 'button');

        stage.classList.add('calendar-stage');
        navi.classList.add('calendar-navi');
        head.classList.add('calendar-head');
        body.classList.add('calendar-body');
        etc.classList.add('calendar-etc');
        prev.classList.add('calendar-prev');
        next.classList.add('calendar-next');
		sely.classList.add('calendar-year');
		selm.classList.add('calendar-month');
		today.classList.add('calendar-today');
		close.classList.add('calendar-close');

		let start;

        if(a.closest('#modal')) {
            stage.style.position = 'fixed';
            stage.style.left = this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().left + 'px';
            stage.style.top = this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().top + this.o.parentElement.querySelector('[name='+ b +']').offsetHeight + 'px';
        }
        else {
            stage.style.position = 'absolute';
            stage.style.left = window.scrollX + this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().left + 'px';
            stage.style.top = window.scrollY + this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().top + this.o.parentElement.querySelector('[name='+ b +']').offsetHeight + 'px';
        }

        prev.innerHTML = '이전';
        next.innerHTML = '다음';
        today.innerHTML = '오늘 날짜';
        close.innerHTML = '닫기';

		for(let i = 0; i <= 100; i++) {
			let y = new Date().getFullYear() - 100 + i;
			let otp = document.createElement('option');
			otp.setAttribute('value', y);
			otp.innerHTML = y;
			sely.append(otp);
		}

		for(let i = 0; i <= 11; i++) {
			let m = i + 1 < 10 ? '0' + (i + 1) : i + 1;
			let otp = document.createElement('option');
			otp.setAttribute('value', i);
			otp.innerHTML = i + 1 + '월';
			selm.append(otp);
		}

        navi.append(prev, sely, selm, next);
		etc.append(today, close);

        stage.append(navi, head, body, etc);

        document.querySelector('body').append(stage);

        start = this.o.parentElement.querySelector('[name='+ b +']').value;

		if(start == '' || start == undefined || start == null) {
			this.year = new Date().getFullYear();
			this.month = new Date().getMonth();
			this.setting(stage, b, this.year, this.month);
		}
		else {
			this.year = Number(start.split('-')[0]);
			this.month = Number(start.split('-')[1]) - 1;
			this.setting(stage, b, this.year, this.month);
		}

        prev.addEventListener('click', () => {
            if(this.month == 0) {
                this.year = this.year - 1;
                this.month = 11;
            }
            else {
                this.year = this.year;
                this.month = this.month - 1;
            }
            this.setting(stage, b, this.year, this.month);
        });
        next.addEventListener('click', () => {
            if(this.month == 11) {
                this.year = this.year + 1;
                this.month = 0;
            }
            else {
                this.year = this.year;
                this.month = this.month + 1;
            }
            this.setting(stage, b, this.year, this.month);
        });
		navi.querySelectorAll('select').forEach((sel) => {
			sel.addEventListener('change', () => {
				this.year = Number(sely.value);
				this.month = Number(selm.value);
				this.setting(stage, b, this.year, this.month);
			});
		});
		today.addEventListener('click', () => {
			this.year = new Date().getFullYear();
			this.month = new Date().getMonth();
            let cm;
            this.month + 1 < 10 ? cm = '0' + (this.month + 1) : cm = this.month + 1;
            this.insert(b, this.year, cm, new Date().getDate());
        });
		close.addEventListener('click', () => {
			a.classList.remove('inactive');
			stage.remove();
			window.removeEventListener('click', handler);
        });
        window.addEventListener('resize', () => {
            a.classList.remove('inactive');
			stage.remove();
			window.removeEventListener('click', handler);
        });

        let handler = (event) => {
            if(event.target != a && event.target != a.previousElementSibling && !event.target.classList.contains('calendar-stage') && event.target.closest('.calendar-stage') == null) {
                a.classList.remove('inactive');
                stage.remove();
                window.removeEventListener('click', handler);
            }
        };

        window.addEventListener('click', handler);
    },
    setting(a, b, c, d) {
        let e;
        d + 1 < 10 ? e = '0' + (d + 1) : e = d + 1;
        let first = new Date(c, d, 1);
        let last = new Date(c, d + 1, 0);
        let weeks = new Array(Math.ceil((first.getDay() + last.getDate()) / 7));
        let days = ['일', '월', '화', '수', '목', '금', '토'];
        let number = 1 - first.getDay();
        let navi = a.querySelector('.calendar-navi');
        let head = a.querySelector('.calendar-head');
        let body = a.querySelector('.calendar-body');
		let prev = navi.querySelector('.calendar-prev');
		let next = navi.querySelector('.calendar-next');
		let year = navi.querySelector('.calendar-year');
		let month = navi.querySelector('.calendar-month');

		for(let i of year.children) {
			if(i.value == c) {
				i.selected = true;
			}
			else {
                i.selected = false;
			}
		}
		for(let i of month.children) {
			if(i.value == d) {
                i.selected = true;
			}
			else {
                i.selected = false;
			}
		}

        head.innerHTML = '';
        body.innerHTML = '';

        let tr = document.createElement('div');

        tr.classList.add('tr');

        head.append(tr);

        for(let i of days) {
            let td = document.createElement('span');

            td.innerHTML = i;

            tr.append(td);
        }


        for(let i of weeks) {
            let tr = document.createElement('div');

            tr.classList.add('tr');

            body.append(tr);

            for(let i in days) {
                let td = document.createElement('span');

                if(c == new Date().getFullYear() && d == new Date().getMonth() && number == new Date().getDate()) {
                    td.classList.add('today');
                }

                if(number < 1 || number > last.getDate()) {
                    td.innerHTML = '';
                }
                else {
                    td.innerHTML = number;
                    td.addEventListener('click', () => {
                        this.insert(b, c, e, td.innerHTML);
                    });
                }

                tr.append(td);
                number++;
            }
        }

		if(this.year == year.firstChild.value && this.month == 0) {
			prev.disabled = true;
		}
		else {
			prev.disabled = false;
		}

		if(this.year == year.lastChild.value && this.month == 11) {
			next.disabled = true;
		}
		else {
			next.disabled = false;
		}
    },
    insert(a, b, c, d) {
        d < 10 ? d = '0' + d : d = d;
        this.o.parentElement.querySelector('[name='+ a +']').value = b +'-'+ c +'-'+ d;
        document.querySelector('.inactive').classList.remove('inactive');
        document.querySelector('.calendar-stage').remove();
    }
};

const monthcalendar = {
    o : '',
    year : '',
    month : '',
    in(a, b) {
        this.o = a;
        if(a.classList.contains('inactive')) {
            a.classList.remove('inactive');
            document.querySelector('.calendar-stage').remove();
            return false;
        }

        a.classList.add('inactive');
        
        let stage = document.createElement('div');
        let navi = document.createElement('div');
        let body = document.createElement('div');
        let etc = document.createElement('div');
		let prev = document.createElement('button');
		let next = document.createElement('button');
		let sely = document.createElement('select');
		let today = document.createElement('button');
		let close = document.createElement('button');

        prev.setAttribute('type', 'button');
        next.setAttribute('type', 'button');
        today.setAttribute('type', 'button');
        close.setAttribute('type', 'button');

        stage.classList.add('calendar-stage', 'month-ver');
        navi.classList.add('calendar-navi');
        body.classList.add('calendar-body');
        etc.classList.add('calendar-etc');
        prev.classList.add('calendar-prev');
        next.classList.add('calendar-next');
		sely.classList.add('calendar-year');
		today.classList.add('calendar-today');
		close.classList.add('calendar-close');

        let start;

        if(a.closest('#modal')) {
            stage.style.position = 'fixed';
            stage.style.left = this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().left + 'px';
            stage.style.top = this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().top + this.o.parentElement.querySelector('[name='+ b +']').offsetHeight + 'px';
        }
        else {
            stage.style.position = 'absolute';
            stage.style.left = window.scrollX + this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().left + 'px';
            stage.style.top = window.scrollY + this.o.parentElement.querySelector('[name='+ b +']').getBoundingClientRect().top + this.o.parentElement.querySelector('[name='+ b +']').offsetHeight + 'px';
        }

        prev.innerHTML = '이전';
        next.innerHTML = '다음';
        today.innerHTML = '현재';
        close.innerHTML = '닫기';

        for(let i = 0; i <= 100; i++) {
			let y = new Date().getFullYear() - 100 + i;
			let otp = document.createElement('option');
			otp.setAttribute('value', y);
			otp.innerHTML = y;
			sely.append(otp);
		}

        navi.append(prev, sely, next);
		etc.append(today, close);

        stage.append(navi, body, etc);

        document.querySelector('body').append(stage);

        start = this.o.parentElement.querySelector('[name='+ b +']').value;

		if(start == '' || start == undefined || start == null || start == '현재') {
			this.year = new Date().getFullYear();
			this.setting(stage, b, this.year);
		}
		else {
			this.year = Number(start.split('-')[0]);
			this.setting(stage, b, this.year);
		}

        prev.addEventListener('click', () => {
            this.year = this.year - 1;
            this.setting(stage, b, this.year);
        });
        next.addEventListener('click', () => {
            this.year = this.year + 1;
            this.setting(stage, b, this.year);
        });
		navi.querySelectorAll('select').forEach((sel) => {
			sel.addEventListener('change', () => {
				this.year = Number(sely.value);
				this.setting(stage, b, this.year);
			});
		});
		today.addEventListener('click', () => {
			this.year = new Date().getFullYear();
            //this.insert(b, this.year, new Date().getMonth() + 1);
            this.o.parentElement.querySelector('[name='+ b +']').value = '현재';
            a.classList.remove('inactive');
            document.querySelector('.calendar-stage').remove();
        });
		close.addEventListener('click', () => {
			a.classList.remove('inactive');
			stage.remove();
			window.removeEventListener('click', handler);
        });
        window.addEventListener('resize', () => {
            a.classList.remove('inactive');
			stage.remove();
			window.removeEventListener('click', handler);
        });

        let handler = (event) => {
            if(event.target != a && event.target != a.previousElementSibling && !event.target.classList.contains('calendar-stage') && event.target.closest('.calendar-stage') == null) {
                a.classList.remove('inactive');
                stage.remove();
                window.removeEventListener('click', handler);
            }
        };

        window.addEventListener('click', handler);
    },
    setting(a, b, c) {
        let line = [1, 2, 3, 4];
        let number = 0;
        let navi = a.querySelector('.calendar-navi');
        let body = a.querySelector('.calendar-body');
		let prev = navi.querySelector('.calendar-prev');
		let next = navi.querySelector('.calendar-next');
		let year = navi.querySelector('.calendar-year');

		for(let i of year.children) {
			if(i.value == c) {
				i.selected = true;
			}
			else {
                i.selected = false;
			}
		}
        
        body.innerHTML = '';

        for(let i of line) {
            let tr = document.createElement('div');

            tr.classList.add('tr');

            body.append(tr);

            for(let i = 0; i < 3; i++) {
                let td = document.createElement('span');

                td.innerHTML = number + 1 + '월';
                td.setAttribute('month', number + 1);
                if(this.year == new Date().getFullYear() && number == new Date().getMonth()) {
                    td.classList.add('today');
                }
                td.addEventListener('click', () => {
                    this.insert(b, c, td.getAttribute('month'));
                });

                tr.append(td);
                number++;
            }
        }

		if(this.year == year.firstChild.value) {
			prev.disabled = true;
		}
		else {
			prev.disabled = false;
		}

		if(this.year == year.lastChild.value) {
			next.disabled = true;
		}
		else {
			next.disabled = false;
		}
    },
    insert(a, b, c) {
        c < 10 ? c = '0' + c : c = c;
        this.o.parentElement.querySelector('[name='+ a +']').value = b +'-'+ c;
        document.querySelector('.inactive').classList.remove('inactive');
        document.querySelector('.calendar-stage').remove();
    }
};