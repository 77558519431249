const aside = {
    x : null,
    y : null,
    in() {
        this.x = window.scrollX;
        this.y = window.scrollY;
        document.querySelector('#aside').style.transform = 'translate3d(0,0,0)';
        document.querySelector('.aside-close').classList.add('active');
        document.querySelector('.aside-bg').classList.add('active');
        document.querySelector('#aside').addEventListener('transitionend', event => {
            document.querySelector('body').classList.add('fixed');
            event.target.classList.add('active');
            window.scrollTo(0, 0);
        }, {once: true});
    },
    out() {
        document.querySelector('body').classList.remove('fixed');
        document.querySelector('#aside').classList.remove('active');
        document.querySelector('#aside').style.transform = 'translate3d(100%,0,0)';
        document.querySelector('.aside-close').classList.remove('active');
        document.querySelector('.aside-bg').classList.remove('active');
        window.scrollTo(this.x, this.y);
    }
};

const headerscroll = () => {
    let h = document.getElementById('header');

    if(window.scrollY >= h.clientHeight) {
        h.classList.add('active');
    }
    else {
        h.classList.remove('active');
    }
};

const headermember = a => {
    let list = a.parentElement.querySelectorAll('a');

    if(a.parentElement.classList.contains('active')) {
        a.parentElement.classList.remove('active');
        a.parentElement.querySelector('div').style.height = '0px';
    }
    else {
        a.parentElement.classList.add('active');
        a.parentElement.querySelector('div').style.height = (29 * list.length + 35) + 'px';
    }
};

const footertop = () => {
    let x = window.scrollX;
    let move = setInterval(() => {
        let y = window.scrollY;
        if(y > 0) {
            window.scrollTo(x, y - (y / 60));
            pcscroll.ban();
        }
        else {
            clearInterval(move);
            pcscroll.clear();
        }
    },1);
};