const itemIndex = a => {
    let items = [...a.parentElement.children];
    return items.indexOf(a);
};

const insertAfter = (a, b) => {
    a.parentElement.insertBefore(b, a.nextSibling);
};

const pcscroll = {
	ban() {
		window.addEventListener('wheel', this.scroll, {passive: false});
	},
	clear() {
		window.removeEventListener('wheel', this.scroll, {passive: false});
	},
	scroll(event) {
        event.preventDefault();
    }
};

const accordion = a => {
    let target = a.parentElement;
    let parent = target.parentElement;

    if(target.classList.contains('active')) {
        target.classList.remove('active');
    }
    else {
        for(let i of parent.children) {
            i.classList.remove('active');
        }

        target.classList.add('active');
    }
};